import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Footer from './Footer'

import styles from './Legal.module.scss'

export const query = graphql`
  query PrivacyPolicyPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

  }
`

const PrivacyPolicyPage = ({ data }) => {
  const { banner, site: { siteMetadata } } = data

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/`,
          "name": "Legal"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/privacy-policy/`,
          "name": "Privacy Policy"
        }
      },
    ]
  }

  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Privacy Policy & Cookies | SloppyDeep`}
      description={`Get informations about SloppyDeep privacy and cookies policy`}
      keywords={`privacy policy sloppydeep, cookies sloppydeep, sloppydeep privacy, sloppydeep policy`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/legal/privacy-policy/`}
      robots='index, follow'
    />
    <div className={`${styles.legalScreen} screen-container`}>
      <div className={styles.documentTitle}>
        <h1>Privacy Policy</h1>
        <span>updated on 13 march 2020</span>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Introduction</h2>
        <div className={styles.sectionContent}>
          Please read this privacy policy carefully, as your access to and use of our Website signifies 
          that you have read, understand and agree to all terms within this privacy policy. If you do not 
          agree with any part of this privacy policy or our terms, please do not access or continue to use 
          our Website. We respect your privacy and are committed to protecting your personal data.<br/><br/>

          If you have any questions about our privacy practices, please contact 
          us: <a href='mailto:contact@sloppydeep.com'>contact@sloppydeep.com</a><br/>
          We collect, process and retain personal data to the extent it is necessary to provide users with 
          our service. This privacy policy applies to information we collect:
          <ul>
            <li>
              on this Website,
            </li>
            <li>
              in e-mail, text and other communications between you and this Website,
            </li>
            <li>
              when you interact with our advertising and applications on third-party websites and services, 
              if those applications or advertising include links to this privacy policy.
            </li>
          </ul>
          <br/>
          It does not apply to information collected by:
          <ul>
            <li>
              us offline or through any other means, including on any other website operated by us or any third 
              party (including our affiliates and subsidiaries); or
            </li>
            <li>
              any third party (including our affiliates and subsidiaries), including through any application 
              or content (including advertising) that may link to or be accessible from or on the Website. 
              Clicking on those links or enabling those connections may allow third parties to collect or share 
              data about you. We do not control these third-party websites and are not responsible for their 
              privacy statements.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>The Data we collect about you</h2>
        <div className={styles.sectionContent}>
          Personal data, or personal information, means any information about an individual from which that 
          person can be identified by (“Personal Information”). It does not include data that has been 
          anonymized or pseudonymized.<br/><br/>

          We may collect, use, store and transfer different kinds of personal data about you, which we have 
          grouped together as follows:<br/><br/>

          Persons who visit the Website “unregistered users”
          <ul>
            <li>
              Technical Data includes internet protocol (IP) address, which we pseudonymize (a technique 
              that replaces or removes information in the dataset that identifies an individual), browser 
              type and version, time zone setting and location, operating system and platform and other 
              technology on the devices you use to access this Website.
            </li>
            <li>
              User Submitted Data includes data collected at your direction for a specific function, for 
              example a contest or survey.
            </li>
            <li>
              Usage Data includes aggregated information about how you use our Website, products and services.
            </li>
            <li>
              Usage Data includes information about how you use our Website, products and services.
            </li>
            <li>
              Marketing and Communications Data includes your preferences in receiving marketing from us and 
              our third parties and your communication preferences.
            </li>
            <li>
              We may also collect your data to produce aggregated insights that do not identify you. 
              Aggregated data may be derived from your personal data but is not considered personal data as 
              this data does not directly or indirectly reveal your identity. For example, we may aggregate 
              your usage data to calculate the percentage of users accessing a specific Website feature, to 
              generate statistics about our users, to calculate the percentage of users accessing a specific 
              Website feature, to calculate ad impressions served or clicked on, or to publish visitor 
              demographics.
            </li>
            <li>
              We do not collect special categories of Personal Information about you (this includes details 
              about your race or ethnicity, religious or philosophical beliefs, political opinions, trade 
              union membership, information about your health and genetic and biometric data).
            </li>
            <li>
              We do not collect any personal information such as your name, surname, address, email.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>How is your Personal Information collected?</h2>
        <div className={styles.sectionContent}>
          We use different methods to collect data from and about you including through:<br/><br/>

          Direct interactions. Information that you provide when carrying out search queries on our Website or 
          by filling in forms on our Website, when reporting a problem with our Website, or requesting further 
          services.<br/>
          Automated technologies or interactions. See “Third-party Use of Cookies and Other Tracking 
          Technologies” for details of how we may automatically collect your personal data.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Information collected through automatic data collection technologies</h2>
        <div className={styles.sectionContent}>
          As you navigate through and interact with our Website, we use automatic data collection technologies 
          to collect certain information about your equipment, browsing actions and patterns, including 
          information such as your IP address, browser type, operating system, the referring web page, pages 
          visited, location, your mobile carrier, device information, search terms, and cookie information.<br/><br/>

          The technologies we use for this automatic data collection may include:
          <ul>
            <li>
              Cookies (or browser cookies). Cookies are small text files that are stored in your web browser 
              or downloaded to your device when you visit a website. Cookies are then sent back to the 
              originating website on each subsequent visit, or to another website that recognizes that 
              cookie, and allow a website to recognize a user’s device.<br/><br/>

              We currently use the following types of cookies:
              <ul>
                <li>
                  Cookies that are strictly necessary: These are cookies that are required for the operation of 
                  our Website. These include, for example, cookies that enable a user to log in to our Website 
                  and to check if a user is allowed access to a particular service or content.
                </li>
                <li>
                  Analytical cookies: These cookies allow us to recognize and count the number of users and to 
                  see how users use and explore our Website. These cookies help us to improve our Website, 
                  for example by ensuring that all users are able to find what they are looking for easily.
                </li>
                <li>
                  Functionality cookies: These cookies are not essential, but help us to personalize and enhance 
                  your online experience on our Website. This type of cookies allows us to recognize you when 
                  you return to our Website and to remember, for example, your choice of language.
                </li>
                <li>
                  Targeting cookies: These cookies record the visit of a user on our Website, the pages a user 
                  has visited and the links a user has followed in order to enable us to make our Website more 
                  relevant to the users' interests.
                </li>
              </ul>
              <br/>
              We do not require that you accept cookies and you may withdraw your consent to our use of cookies 
              at any time by adjusting your browser’s privacy settings. However, if you decline to accept 
              cookies, some functionality on our Website may be disabled and you may be unable to access 
              certain parts of our Website. Unless you have adjusted your browser setting so that it will 
              refuse cookies, our system will issue cookies when you direct your browser to our Website. 
              Cookies can be either session cookies or persistent cookies. A session cookie expires 
              automatically when you close your browser. A persistent cookie will remain until it expires or 
              you delete your cookies. Expiration dates are set in the cookies themselves; some may expire 
              after a few minutes while others may expire after multiple year.
            </li>
            <li>
              Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as 
              web beacons (also known as clear gifs, pixel tags, single-pixel gifs and web bugs) that are 
              tiny graphics with a unique identifier, similar in function to cookies, and are used to track 
              the online movements of web users or to access cookies.
            </li>
            <li>
              Analytics. We use third party analytics and advertising tools and technologies, in particular 
              Google Analytics and DoubleClick provided by Google, Inc., USA (“Google”). These tools and 
              technologies collect and analyze certain types of information, including IP addresses, device 
              and software identifiers, referring and exit URLs, onsite behavior and usage information, feature 
              use metrics and statistics, usage and purchase history, media access control address (MAC 
              Address), mobile unique device identifiers, and other similar information via the use of cookies. 
              The information generated by Google Analytics and DoubleClick about your use of the Website 
              (including your IP address) may be transmitted to and stored by Google on servers in the United 
              States. Because we activated IP anonymization for Google Analytics and Double Click, Google will 
              anonymize the last octet of a particular IP address. Only in exceptional cases, the full IP 
              address is sent to and shortened by Google servers in the USA. Google will use this information 
              for the purpose of evaluating your use of the Website, compiling reports on Website activity 
              and managing advertising content. To learn how you can opt out from this information collection 
              by Google see “Choices About How We Collect, Use and Disclose Your Personal Information” below.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Third-party use of Cookies and other Tracking Technologies</h2>
        <div className={styles.sectionContent}>
          Some content or applications, including advertisements, on the Website are served by third parties, 
          including advertisers, ad networks and servers, content providers and application providers. 
          These third parties may use cookies alone or in conjunction with web beacons or other tracking 
          technologies to collect information about you when you use our Website. Unless expressly stated 
          otherwise, our website does not provide any Personal Information to these third parties, however 
          they may collect information, including Personal Information, such as internet protocol (IP) 
          address, browser type and version, time zone setting and location, operating system and platform 
          and other technology on the devices you use to access this Website. They may use this information 
          to provide you with interest-based advertising or other targeted content.<br/><br/>

          We do not control these third parties’ tracking technologies or how they may be used. If you have 
          any questions about an advertisement or other targeted content, you should contact the responsible 
          provider directly. For information about how you can opt out of receiving targeted advertising from 
          many providers, see “Choices About How We Collect, Use and Disclose Your Personal Information”.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>How we use your Personal Data</h2>
        <div className={styles.sectionContent}>
          We will only use your personal data when the applicable local law allows us to. Most commonly, 
          we will use your personal data in the following circumstances:
          <ul>
            <li>
              For purposes of providing the services, customer management and functionality and security as 
              is necessary to perform the services provided to you under our term and conditions and any other 
              contract that you have with us.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and your interests 
              and fundamental rights do not override those interests.
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation.
            </li>
            <li>
              Where you declare your valid consent to use it.
            </li>
          </ul>
          <br/>
          Note that we may process your personal data for more than one lawful ground depending on the 
          specific purpose for which we are using your data.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Disclosure of your Personal Information</h2>
        <div className={styles.sectionContent}>
          We will NEVER disclose your Personal Information except in the limited circumstances described here.
          <ul>
            <li>
              We may disclose your Personal Information to members of our corporate group (that is, entities 
              that control, are controlled by, or are under common control with us) to the extent this is 
              necessary for the purposes of provision of services, customer management, customization of 
              content, advertising, analytics, verifications, functionality and security, and compliance.
            </li>
            <li>
              We access, preserve and share your Personal Information with regulators, law enforcement or 
              others where we reasonably believe such disclosure is needed to (a) satisfy any applicable 
              law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, 
              including investigation of potential violations thereof, (c) detect, prevent, or otherwise 
              address illegal or suspected illegal activities, security or technical issues, (d) protect 
              against harm to the rights, property or safety of our company, our users, our employees, or 
              others; or (e) to maintain and protect the security and integrity of our Website or 
              infrastructure. In such cases, we may raise or waive any legal objection or right available 
              to us, in our sole discretion.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>How we Protect the Security of your Personal Information</h2>
        <div className={styles.sectionContent}>
          We take appropriate security measures (including physical, electronic and procedural measures) to 
          safeguard your Personal Information from unauthorized access and disclosure. For example, 
          only authorized employees are permitted to access Personal Information, and they may do so 
          only for permitted business functions. In addition, we use encryption in the transmission of 
          your Personal Information between your system and ours, and we use firewalls to help prevent 
          unauthorized persons from gaining access to your Personal Information. Please be advised, however, 
          that we cannot fully eliminate security risks associated with the storage and transmission of 
          Personal Data.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Changes to our Privacy Policy</h2>
        <div className={styles.sectionContent}>
          We may modify or revise our privacy policy from time to time. Although we may attempt to notify 
          you when major changes are made to this privacy policy, you are expected to periodically review 
          the most up-to-date version found here.<br/><br/>

          If we change anything in our privacy policy, the date of change will be reflected in the “last 
          modified date”. You agree that you will periodically review this privacy policy and refresh the 
          page when doing so. You agree to note the date of the last revision to our privacy policy. If 
          the “last modified” date is unchanged from the last time you reviewed our privacy policy, then 
          it is unchanged. On the other hand, if the date has changed, then there have been changes, and you 
          agree to re-review our privacy policy, and you agree to the new ones. By continuing to use the 
          Website subsequent to us making available an amended version of our privacy policy in a way that 
          you can easily take notice of it, you thereby consent to such amendment.
        </div>
      </div>
      <Footer />
    </div>
    </>
  )
}

export default PrivacyPolicyPage
