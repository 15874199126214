import React from 'react'
import { Link } from 'gatsby'

import LogoRTA from 'assets/images/rtaLabel.gif'

import styles from './Footer.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.rightsContainer}>
      <address className={styles.rights}>
        © 2020 Copyright Sloppydeep.com<br/>
        DeeperStudio IT services™<br/>
        Administration contact - contact@sloppydeep.com<br/>
        +353 899 885 347
      </address>
      <a href='https://rtalabel.org' className={styles.rtaLabel}>
        <img src={LogoRTA} alt='RTA label logo' />
      </a>
    </div>
    <ul className={styles.menu}>
      <li className={styles.menuItem}>
        <Link to={'/legal/terms-of-service/'} className={styles.menuLink}>Terms of Service</Link>
      </li>
      <li className={styles.menuItem}>
        <Link to={'/legal/privacy-policy/'} className={styles.menuLink}>Privacy Policy</Link>
      </li>
      <li className={styles.menuItem}>
        <Link to={'/legal/dmca/'} className={styles.menuLink}>2257</Link>
      </li>
      <li className={styles.menuItem}>
        <Link to={'/legal/dmca/'} className={styles.menuLink}>DMCA</Link>
      </li>
    </ul>
  </div>
)

export default Footer